@media (min-width:320px) and (max-width:1024px)  {
    /* smartphones, iPhone, portrait 480x320 phones */ 

    .nav-bar {
        background-color: #287f24;
        width: 100%;
        height: 16em;
        display: flex;
        flex-direction: column;
        border-bottom: 2px black solid;
        box-shadow: 0 0 6px 0;
        /* position: sticky; */
        top: 0;
        z-index: 10;
    }
    
    .nav-bar--logo {
        padding: 6px;
        /* width: fit-content; */
        width: 100%;
        /* width: 80%; */
        /* object-fit: contain; */
        height: 4em;
        display: inline-block;
        font-size: 30px;
        font-weight: bold;
        color: white;
        box-sizing: border-box;
        text-align: center;
        text-decoration: none;
        /* margin: 0 auto; */
        /* margin-bottom: 0.25em; */
    }
    
    .nav-bar--logo_img {
        object-fit: contain;
        max-height: 3.5em;
        /* height: 4em; */
        width: 95%;
    }
    
    .nav-bar--button-container {
        margin: 0.25em auto;
        height: 2em;
        display: flex;
        flex-direction: row;
        gap: 1em;
    }

    .nav-bar--social-container {
        display: flex;
        width: max-content;
        max-width: 14em;
        gap: 0.5em;
        margin: 0 auto;
        flex-grow: 0;
    }

    .nav-bar--social-button {
        cursor: pointer;
        width: 2em;
        height: 2em;
        border-radius: 1.25em;
        border: none;
        font-size: 1em;
        padding: 0.2em;
        background-color: #e4322c;
        color: #ffffff;
        display: flex;
        text-decoration: none;
    }

    .nav-bar--social-button:hover {
        background-color: #ffffff;
        color: #e4322c;
    }

    .nav-bar--social-button_icon {
        margin: auto;
    }

    .nav-bar--donation-container {
        margin: auto;
        display: flex;
        width: max-content;
        padding: 1em;
        /*flex-grow: 0;*/
    }

    .nav-bar--donation-button {
        background-color: #e4322c;
        border-color: #ffffff;
        border-style: solid;
        border-radius: 0.5em;
        border-width: 2px;
        width: 8em;
        color: #ffffff;
        font-weight: 800;
        font-size: 1.5em;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.5em;
    }

    .nav-bar--donation-button:hover {
        background-color: #ffffff;
        border-color: #e4322c;
        color: #e4322c;
    }
    
    .nav-bar--route-link {
        font-weight: 800;
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        padding: 6px;
    }
    
    .nav-bar--route-link_selected,
    .nav-bar--route-link:hover {
        background-color: #e4322c;
        border-radius: 0.5em;
    }
    
    a {
        cursor: pointer;
    }
}
/* 
@media (min-width:481px)  {

    .nav-bar {
        background-color: #287f24;
        width: 100%;
        height: 6em;
        display: flex;
        flex-direction: row;
        border-bottom: 2px black solid;
        box-shadow: 0 0 6px 0;
        position: sticky;
        top: 0;
    }
    
    .nav-bar--logo {
        padding: 6px 50px;
        width: fit-content;
        height: 100%;
        display: inline-block;
        font-size: 30px;
        font-weight: bold;
        color: white;
        box-sizing: border-box;
        text-align: center;
        text-decoration: none;
    }
    
    .nav-bar--logo_img {
        object-fit: contain;
        height: 100%;
    }
    
    .nav-bar--button-container {
        margin: auto 5em;
        display: flex;
        flex-direction: row;
        gap: 1em;
    }
    
    .nav-bar--route-link {
        font-weight: 800;
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        padding: 6px;
    }
    
    .nav-bar--route-link_selected,
    .nav-bar--route-link:hover {
        background-color: #e4322c;
        border-radius: 0.5em;
    }
    
    a {
        cursor: pointer;
    }
}

@media (min-width:641px)  {

    .nav-bar {
        background-color: #287f24;
        width: 100%;
        height: 6em;
        display: flex;
        flex-direction: row;
        border-bottom: 2px black solid;
        box-shadow: 0 0 6px 0;
        position: sticky;
        top: 0;
    }
    
    .nav-bar--logo {
        padding: 6px 50px;
        width: fit-content;
        height: 100%;
        display: inline-block;
        font-size: 30px;
        font-weight: bold;
        color: white;
        box-sizing: border-box;
        text-align: center;
        text-decoration: none;
    }
    
    .nav-bar--logo_img {
        object-fit: contain;
        height: 100%;
    }
    
    .nav-bar--button-container {
        margin: auto 5em;
        display: flex;
        flex-direction: row;
        gap: 1em;
    }
    
    .nav-bar--route-link {
        font-weight: 800;
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        padding: 6px;
    }
    
    .nav-bar--route-link_selected,
    .nav-bar--route-link:hover {
        background-color: #e4322c;
        border-radius: 0.5em;
    }
    
    a {
        cursor: pointer;
    }
}

@media (min-width:961px)  {

    .nav-bar {
        background-color: #287f24;
        width: 100%;
        height: 6em;
        display: flex;
        flex-direction: row;
        border-bottom: 2px black solid;
        box-shadow: 0 0 6px 0;
        position: sticky;
        top: 0;
    }
    
    .nav-bar--logo {
        padding: 6px 50px;
        width: fit-content;
        height: 100%;
        display: inline-block;
        font-size: 30px;
        font-weight: bold;
        color: white;
        box-sizing: border-box;
        text-align: center;
        text-decoration: none;
    }
    
    .nav-bar--logo_img {
        object-fit: contain;
        height: 100%;
    }
    
    .nav-bar--button-container {
        margin: auto 5em;
        display: flex;
        flex-direction: row;
        gap: 1em;
    }
    
    .nav-bar--route-link {
        font-weight: 800;
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        padding: 6px;
    }
    
    .nav-bar--route-link_selected,
    .nav-bar--route-link:hover {
        background-color: #e4322c;
        border-radius: 0.5em;
    }
    
    a {
        cursor: pointer;
    }
} */

/* @media (min-width:1025px) {

    .nav-bar {
        background-color: #287f24;
        width: 100%;
        height: 6em;
        display: flex;
        flex-direction: row;
        border-bottom: 2px black solid;
        box-shadow: 0 0 6px 0;
        position: sticky;
        top: 0;
    }
    
    .nav-bar--logo {
        padding: 6px 50px;
        width: fit-content;
        height: 100%;
        display: inline-block;
        font-size: 30px;
        font-weight: bold;
        color: white;
        box-sizing: border-box;
        text-align: center;
        text-decoration: none;
    }
    
    .nav-bar--logo_img {
        object-fit: contain;
        height: 100%;
    }
    
    .nav-bar--button-container {
        margin: auto 5em;
        display: flex;
        flex-direction: row;
        gap: 1em;
    }
    
    .nav-bar--route-link {
        font-weight: 800;
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        padding: 6px;
    }
    
    .nav-bar--route-link_selected,
    .nav-bar--route-link:hover {
        background-color: #e4322c;
        border-radius: 0.5em;
    }
    
    a {
        cursor: pointer;
    }
} */

@media (min-width:1025px)/*(min-width:1281px)*/ {
    /* hi-res laptops and desktops */
    
    .nav-bar {
        background-color: #287f24;
        width: 100%;
        height: 6em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 2px black solid;
        box-shadow: 0 0 6px 0;
        position: sticky;
        top: 0;
        z-index: 10;
    }
    
    .nav-bar--logo {
        padding: 6px 0 6px 4%;
        width: fit-content;
        height: 100%;
        display: inline-block;
        font-size: 30px;
        font-weight: bold;
        color: white;
        box-sizing: border-box;
        text-align: center;
        text-decoration: none;
        flex-grow: 0;
    }
    
    .nav-bar--logo_img {
        object-fit: contain;
        height: 100%;
        width: max-content;
    }
    
    .nav-bar--button-container {
        margin: auto;
        justify-content: center;
        display: flex;
        flex-direction: row;
        gap: 1em;
        flex-grow: 2;
        flex-wrap: wrap;
    }

    .nav-bar--social-container {
        display: flex;
        width: max-content;
        height: max-content;
        max-height: 100%;
        max-width: 14em;
        gap: 0.5em;
        margin: auto;
        flex-grow: 1;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .nav-bar--social-button {
        cursor: pointer;
        width: 2em;
        height: 2em;
        border-radius: 1.25em;
        border: none;
        font-size: 1em;
        padding: 0.2em;
        background-color: #e4322c;
        color: #ffffff;
        display: flex;
        text-decoration: none;
    }

    .nav-bar--social-button:hover {
        background-color: #ffffff;
        color: #e4322c;
    }

    .nav-bar--social-button_icon {
        margin: auto;
    }

    .nav-bar--donation-container {
        margin-right: 0;
        margin-left: auto;
        display: flex;
        width: max-content;
        padding: 1em;
        flex-grow: 0;
    }

    .nav-bar--donation-button {
        background-color: #e4322c;
        border-color: #ffffff;
        border-style: solid;
        border-radius: 0.5em;
        border-width: 2px;
        width: 8em;
        color: #ffffff;
        font-weight: 800;
        font-size: 1.5em;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nav-bar--donation-button:hover {
        background-color: #ffffff;
        border-color: #e4322c;
        color: #e4322c;
    }
    
    .nav-bar--route-link {
        font-weight: 800;
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        padding: 6px;
    }
    
    .nav-bar--route-link_selected,
    .nav-bar--route-link:hover {
        background-color: #e4322c;
        border-radius: 0.5em;
    }
    
    a {
        cursor: pointer;
    }    
}
