@media (min-width:320px) and (max-width: 1024px) {
    .home-page--banner-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: max-content;
        background-color: #ffffff;
        border-bottom: 2px black solid;
        box-shadow: 0 0 6px 0;
    }
    
    .home-page--banner-image {
        width: 60%;
        margin: 0 auto;
    }
    
    .home-page--banner-logo-container {
        display: flex;
        flex-direction: column;
        margin: auto;
        height: max-content;
        width: 100%;
    }
    
    .home-page--banner-logo {
        width: 95%;
        height: max-content;
        object-fit: contain;
        margin: 1em auto 0 auto;
    }
    
    .home-page--banner-text {
        font-size: 20px;
        font-weight: 600;
        width: 80%;
        margin: auto;
        padding: 1em;
        text-align: center;
    }

    .yard-sign-link-home--button {
        background-color: #e4322c;
        border-color: #ffffff;
        border-style: solid;
        border-radius: 0.5em;
        border-width: 2px;
        width: 12em;
        color: #ffffff;
        font-weight: 800;
        font-size: 1.5em;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        margin: 0.5em auto;
    }

    .yard-sign-link-home--button:hover {
        background-color: #ffffff;
        border-color: #e4322c;
        color: #e4322c;
    }
}

@media (min-width:1025px) {
    .home-page--banner-background {
        width: 100%;
        height: max-content;
        background-color: #ffffff;
        border-bottom: 2px black solid;
        box-shadow: 0 0 6px 0;
    }

    .home-page--banner-container {
        width: 100%;
        max-width: 2000px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
    }
    
    .home-page--banner-image {
        width: 30em;
    }
    
    .home-page--banner-logo-container {
        display: flex;
        flex-direction: column;
        margin: auto;
        height: max-content;
        width: max-content;
    }
    
    .home-page--banner-logo {
        width: 50em;
        object-fit: contain;
        margin: 0 auto;
    }
    
    .home-page--banner-text {
        font-size: 24px;
        font-weight: 600;
        width: 80%;
        margin: auto;
    }

    .home-page--video-container, iframe {
        margin: auto;
        display: block;
        max-width: 100%;
    }

    .yard-sign-link-home--button {
        background-color: #e4322c;
        border-color: #ffffff;
        border-style: solid;
        border-radius: 0.5em;
        border-width: 2px;
        width: 12em;
        color: #ffffff;
        font-weight: 800;
        font-size: 1.5em;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        margin: 0.5em auto;
    }

    .yard-sign-link-home--button:hover {
        background-color: #ffffff;
        border-color: #e4322c;
        color: #e4322c;
    }
}