@media (min-width:320px) and (max-width: 1024px) /* (max-width: 480px) */ {
    /* smartphones, iPhone, portrait 480x320 phones */

    .base-page-wrapper {
        /* padding: 1em 1em 3em 1em; */
        /* height: 100vh; */
        padding-bottom: 4em;
        margin: 2em;
    }
    
    .page-content--container_max-width {
        max-width: 1100px;
        margin: 0 auto;
    }
    
    .page-content--container_max-width > p {
        font-size: 18px;
        margin: 0;
    }

    p {
        font-size: 18px;
        padding-bottom: 1em;
        margin: 0 !important;
    }

    .emphasis {
        font-size: 18px;
        font-weight: 600;
        color: #e4322c;
    }
    
    /* h1 {
        font-size: 32px;
        text-align: center;
    } */

    h1 {
        font-size: 36px;
        text-align: center;
        color: #287f24;
        margin: 0 0 1em 0; /* ??? */
        padding-block-start: 0.67em;
    }

    h2 {
        font-size: 26px;
        text-align: center;
        color: #287f24;
        margin: 50px 0;
    }

    .paragraph-container {
        display: flex;
        flex-direction: column;
        height: max-content;
    }

    .paragraph--image {
        border: #e4322c 2px solid;
        box-shadow: 0 0 6px 0;
        margin-top: 25px;
        margin: 2em auto;
    }
    
    .upper-case {
        text-transform: uppercase;
    }
}

/* @media (min-width:481px) and (max-width: 640px) {
    

    .base-page-wrapper {
        padding: 5em;
    }
    
    .page-content--container_max-width {
        max-width: 1100px;
        margin: 0 auto;
    }
    
    p {
        font-size: 18px;
    }
    
    h1 {
        font-size: 32px;
        text-align: center;
    }
    
    .upper-case {
        text-transform: uppercase;
    }
} */

/* @media (min-width:641px) and (max-width: 960px) {


    .base-page-wrapper {
        padding: 5em;
    }
    
    .page-content--container_max-width {
        max-width: 1100px;
        margin: 0 auto;
    }
    
    p {
        font-size: 18px;
    }
    
    h1 {
        font-size: 32px;
        text-align: center;
    }
    
    .upper-case {
        text-transform: uppercase;
    }
} */

/* @media (min-width:961px) and (max-width: 1024px) {
    

    .base-page-wrapper {
        padding: 5em;
    }
    
    .page-content--container_max-width {
        max-width: 1100px;
        margin: 0 auto;
    }
    
    p {
        font-size: 18px;
    }
    
    h1 {
        font-size: 32px;
        text-align: center;
    }
    
    .upper-case {
        text-transform: uppercase;
    }
} */

/* @media (min-width:1025px) and (max-width: 1280px) {

    .base-page-wrapper {
        padding: 5em;
    }
    
    .page-content--container_max-width {
        max-width: 1100px;
        margin: 0 auto;
    }
    
    p {
        font-size: 18px;
    }
    
    h1 {
        font-size: 32px;
        text-align: center;
    }
    
    .upper-case {
        text-transform: uppercase;
    }
} */

@media (min-width:1025px) {
    /* hi-res laptops and desktops */

    .base-page-wrapper {
        padding: 5em;
        /* min-height: calc(100vh - 16em); */
        /* min-height: auto; */
    }

    .base-page--background {
        background: 
            linear-gradient(to right, #ffffff00 0%, #ffffff calc((100% - 1100px - 4em) / 2), #ffffff calc(100% - ((100% - 1100px - 4em) / 2)), #ffffff00 100%),
            url('../images/dt-aurora-temp-background-cropped.jpeg');
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        top: 0;
        min-height: calc(100vh - 18.1em);
    }
    
    .page-content--container_max-width {
        max-width: 1100px;
        margin: 0 auto;
        /* min-height: 100vw; */
    }
    
    p {
        font-size: 18px;
        margin-top: 0;
    }

    .emphasis {
        font-size: 18px;
        font-weight: 600;
        color: #e4322c;
    }
    
    h1 {
        font-size: 56px;
        text-align: center;
        color: #287f24;
        margin: 0; /* ??? */
        padding-block-start: 0.67em;
    }

    h2 {
        font-size: 40px;
        text-align: center;
        color: #287f24;
        margin: 50px;
    }

    .paragraph-container {
        display: flex;
        flex-direction: row;
        height: max-content;
    }

    .paragraph--image {
        border: #e4322c 2px solid;
        box-shadow: 0 0 6px 0;
        margin-top: 25px;
    }
    
    .upper-case {
        text-transform: uppercase;
    }
}
