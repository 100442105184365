@media (max-width:1024px) {
    .footer-container {
        width: 100%;
        min-height: 12em;
        height: max-content;
        background-color: #287f24;
        border-top: 2px black solid;
        box-shadow: 0 0 6px 0;
        box-sizing: border-box;
        padding: 1em 1em 4em 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer--content {
        height: max-content;
        margin: 0 auto 2em;
        width: 100%;
        text-align: center;
        color: #ffffff;
        font-size: 18px;
    }

    .footer--link {
        color: #ffffff;
        text-decoration: none;
    }

    .footer--link:hover {
        text-decoration: underline;
    }

    .footer--logo_img {
        height: 6em;
        object-fit: contain;
        width: max-content;
        max-width: 100%;
        margin: 0 auto;
    }

    .yard-sign-link--container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.25em;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        margin: 0 1.5em 2em 1.5em;
    }

    .yard-sign-link--button {
        background-color: #e4322c;
        border-color: #ffffff;
        border-style: solid;
        border-radius: 0.5em;
        border-width: 2px;
        width: 12em;
        color: #ffffff;
        font-weight: 800;
        font-size: 1.5em;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        margin: auto;
    }

    .yard-sign-link--button:hover {
        background-color: #ffffff;
        border-color: #e4322c;
        color: #e4322c;
    }
}

@media (min-width:1025px) {
    .footer-container {
        width: 100%;
        height: 12em;
        background-color: #287f24;
        border-top: 2px black solid;
        box-shadow: 0 0 6px 0;
        box-sizing: border-box;
        padding: 1em;
        bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .footer--content {
        text-align: center;
        color: #ffffff;
        font-size: 18px;
        max-width: 500px;
        flex: 1;
        min-width: 0;
    }

    .footer--link {
        color: #ffffff;
        text-decoration: none;
    }

    .footer--link:hover {
        text-decoration: underline;
    }

    .img--container {
        flex: 1;
        min-width: 0;
        max-width: 500px;
        max-height: 100%;
    }

    .footer--logo_img {
        /* height: 60%; */
        height: 100%;
        object-fit: contain;
        /* width: max-content; */
        width: 100%;
        /* max-width: 30em; */
    }

    .yard-sign-link--container {
        flex: 1;
        min-width: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5em;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        /* margin: 1.5em; */
        max-width: 500px;
    }

    .yard-sign-link--button {
        background-color: #e4322c;
        border-color: #ffffff;
        border-style: solid;
        border-radius: 0.5em;
        border-width: 2px;
        width: 12em;
        color: #ffffff;
        font-weight: 800;
        font-size: 1.5em;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        /* margin: auto; */
    }

    .yard-sign-link--button:hover {
        background-color: #ffffff;
        border-color: #e4322c;
        color: #e4322c;
    }
}