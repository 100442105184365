.tab-bar {
    position: fixed;
    bottom: 0;
    background: white;
    border-top: 2px black solid;
    box-shadow: 2px 0 0 0;
    height: 3em;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 3em;
}

.tab-bar--item {
    text-transform: uppercase;
    color: black;
    margin: auto;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.1em;
    padding: 0;
}

.tab-bar--item_selected,
.tab-bar--item:hover {
    color: #e4322c;
}
