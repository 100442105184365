@media (max-width:1024px) {
    .contact-container {
        display: flex;
        flex-direction: column;
        width: max-content;
        max-width: 100%;
        height: max-content;
        max-width: 100%;
        gap: 2em;
        margin: 4em auto 0 auto;
        align-content: center;
    }

    .contact-card {
        background-color: #287f24;
        border-radius: 1em;
        width: 18em;
        height: 12em;
        display: flex;
        flex-direction: column;
        align-content: center;
    }

    .contact-card--icon {
        margin: 0.5em auto 0.4em auto;
        font-size: 40px;
        color: #ffffff;
    }

    .contact-card--label {
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
    }

    .contact-card--content {
        font-size: 18px;
        color: #ffffff;
        text-align: center;
        text-decoration: none;
        padding: 1em;
    }

    .contact-card--content:hover {
        text-decoration: underline;
    }
}

@media (min-width:1025px) {
    .contact-container {
        display: flex;
        flex-direction: row;
        width: max-content;
        height: 12em;
        max-width: 100%;
        gap: 2em;
        margin: 4em auto;
        align-content: center;
    }

    .contact-card {
        background-color: #287f24;
        border-radius: 1em;
        width: 18em;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
    }

    .contact-card--icon {
        margin: 0.5em auto 0.4em auto;
        font-size: 40px;
        color: #ffffff;
    }

    .contact-card--label {
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
    }

    .contact-card--content {
        font-size: 18px;
        color: #ffffff;
        text-align: center;
        text-decoration: none;
        padding: 1em;
    }

    .contact-card--content:hover {
        text-decoration: underline;
    }
}