.yard-sign-information {
    margin-top: 2em;
    padding-bottom: 1em;
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
}

.yard-sign-form-wrapper {
    padding-top: 2em;
    position: relative;
    overflow: hidden;
}

.input-label-container {
    display: inline-flex;
    flex-direction: column;
    /* gap: 0.5em; */
    width: 100%;
}

label {
    padding: 0.5em 0;
}

.full-width {
    width: 100%;
}

@media (max-width: 1024px) {
    #yard-sign-information {
        top: 3em;
        position: relative;
    }

    .half-width {
        width: 100%;
    }
    
    .address-input {
        /* flex: 1 1 calc(50% - 0.5em);
        max-width: calc(50% - 0.5em); */
    }

    .two-input-group {
        display: flex;
        /* justify-content: space-between; */
        gap: 1em;
        flex-wrap: wrap;
    }

    .submit-button {
        background-color: #e4322c;
        border-color: #e4322c;
        border-style: solid;
        border-radius: 0.5em;
        border-width: 2px;
        width: 8em;
        height: 2em;
        color: #ffffff;
        font-weight: 800;
        font-size: 1.5em;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .submit-button:hover {
        background-color: #ffffff;
        border-color: #e4322c;
        color: #e4322c;
    }

    .toast-messages {
        margin: 3.5em auto;
        width: 90%;
        max-width: 420px;
        left: 50%;
        transform: translate(-50%, 0%);
    }

    .recaptcha-container {
        margin: 2em 0;
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 1025px) {
    #yard-sign-information {
        top: -5em;
        position: relative;
    }

    .half-width {
        /* width: 49%; */
        flex: 1;
    }

    .address-input {
        flex: 1 1 calc(50% - 0.5em);
        max-width: calc(50% - 0.5em);
    }

    .two-input-group {
        display: flex;
        /* justify-content: space-between; */
        gap: 1em;
    }

    .submit-button {
        background-color: #e4322c;
        border-color: #e4322c;
        border-style: solid;
        border-radius: 0.5em;
        border-width: 2px;
        width: 8em;
        height: 2em;
        color: #ffffff;
        font-weight: 800;
        font-size: 1.5em;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .submit-button:hover {
        background-color: #ffffff;
        border-color: #e4322c;
        color: #e4322c;
    }

    .recaptcha-container {
        margin: 2em 0;
    }
}

.address-group {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
}

.required {
    color: #e4322c;
    /* font-style: italic; */
    font-weight: normal;
    font-size: 14px;
}

.text-input, .select-input {
    font-size: 15px;
    padding: 0.5em;
    border: 1px solid #777;
    border-radius: 0.25em;
}

.errored {
    border-color: #e4322c;
}

.errored--label {
    color: #e4322c;
}

.textarea-input {
    font-size: 15px;
    padding: 0.5em;
    border: 1px solid #777;
    border-radius: 0.25em;
    resize: vertical;
    width: 100%;
    height: 12em;
    box-sizing: border-box;
}

.text-input:focus, .textarea-input:focus {
    border-color: #287f24;
    outline: none;
}

.select-input:focus {
    border-color: #287f24;
    outline: none;
}

.select-input:hover {
    filter: brightness(90%);
}

.additional-comments-label {
    font-weight: bold;
    font-size: 18px;
}

.legend-style {
    font-weight: bold;
    font-size: 18px;
}

.fieldset-style {
    /* border: none; */
    border-radius: 0.5em;
    border-color: #bbb;
    border-width: 1px;
    margin: 1em 0 2em 0;
    padding: 1em;
}

.loading {
    opacity: 0.5;
}